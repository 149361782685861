import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import GlobalTokens from '@sainsburys-tech/tokens/dist/global/js/json/color/index.json';
import ColourBlock from '@components/colour-block';
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableRow, TableCell } from '@jsluna/table';
import { Tick, Cancel } from '@jsluna/icons';
import { PrimaryColours, SecondaryColours, PromotionalColours, BaseColours, ExtendedColours, MonochromeColours, SemanticColours, ColourLookup } from '@components/design-tokens';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const TableCellResult = makeShortcode("TableCellResult");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#lunas-colour-principles"
            }}>{`Luna’s colour principles`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#the-luna-colour-system"
            }}>{`The Luna colour system`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#palettes"
            }}>{`Palettes`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#colour-accessibility"
            }}>{`Colour accessibility`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "lunas-colour-principles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lunas-colour-principles",
        "aria-label": "lunas colour principles permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Luna’s colour principles`}</h2>
    <GridList columns="3" mdxType="GridList">
  <GridListItem title="Accessible" titleElement="h3" border mdxType="GridListItem">
    We strive to make sure that every colour we use meets accessibility
    standards. And if it doesn’t, we suggest alternatives to use within the Luna
    colour system.
  </GridListItem>
  <GridListItem title="Distinctive" titleElement="h3" border mdxType="GridListItem">
    Each brand has unique colours that form a key part of their visual identity.
  </GridListItem>
  <GridListItem title="Consistent" titleElement="h3" border mdxType="GridListItem">
    Luna features shared colours for the brands that can add consistency and
    clarity when using colour.
  </GridListItem>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "the-luna-colour-system",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-luna-colour-system",
        "aria-label": "the luna colour system permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`The Luna colour system`}</h2>
    <p>{`Each brand that uses the Luna colour system has its own individual palettes. Each of these brand palettes consist of base colours and extended colours. The base colours refer to the distinctive brand colours, while the extended colours consist of all the useable tints and shades of those base colours.`}</p>
    <h3>{`Example of a base colour`}</h3>
    <GridList columns="2" mdxType="GridList">
  <ColourBlock fill="#1976D2" mdxType="ColourBlock">Luna Base</ColourBlock>
    </GridList>
    <h3>{`Example of extended colours`}</h3>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#114E8B" mdxType="ColourBlock">Luna Darkest</ColourBlock>
  <ColourBlock fill="#135CA3" mdxType="ColourBlock">Luna Darker</ColourBlock>
  <ColourBlock fill="#1669BA" mdxType="ColourBlock">Luna Dark</ColourBlock>
  <ColourBlock fill="#428FDA" mdxType="ColourBlock">Luna Light</ColourBlock>
  <ColourBlock fill="#95C0EA" mdxType="ColourBlock">Luna Lighter</ColourBlock>
  <ColourBlock fill="#E8F1FB" mdxType="ColourBlock">Luna Lightest</ColourBlock>
    </GridList>
    <p>{`Start your designs using the base colours first, as these are the distinctive colours that users attribute to the brand.`}</p>
    <p>{`Once you’ve used the base colours in your designs, the extended colours come in handy for creating contrast, adding subtle backgrounds, highlighting various interactive states and much more.`}</p>
    <h3>{`Brands using the Luna colour system`}</h3>
    <p>{`Currently, not all brands are using the Luna colour system. You can see a summary of how each brand uses the colour system below.`}</p>
    <TableContainer className="ln-u-margin-bottom" mdxType="TableContainer">
  <TableHeader mdxType="TableHeader">
    <TableHeaderRow mdxType="TableHeaderRow">
      <TableHeaderCell mdxType="TableHeaderCell">Brand</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">Extended colours</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">Promotional palette</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">Monochrome palette</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">Semantic palette</TableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Sainsbury's</TableCell>
      <TableCellResult mdxType="TableCellResult">
        <Cancel title="No" mdxType="Cancel" />
      </TableCellResult>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Argos</TableCell>
      <TableCellResult mdxType="TableCellResult">
        <Cancel title="No" mdxType="Cancel" />
      </TableCellResult>
      <TableCellResult mdxType="TableCellResult">
        <Cancel title="No" mdxType="Cancel" />
      </TableCellResult>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
      <TableCellResult mdxType="TableCellResult">
        <Cancel title="No" mdxType="Cancel" />
      </TableCellResult>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Nectar</TableCell>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
      <TableCellResult mdxType="TableCellResult">
        <Cancel title="No" mdxType="Cancel" />
      </TableCellResult>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
      <TableCellResult mdxType="TableCellResult">
        <Cancel title="No" mdxType="Cancel" />
      </TableCellResult>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Habitat</TableCell>
      <TableCellResult success={true} mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Tu</TableCell>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
      <TableCellResult success mdxType="TableCellResult">
        <Tick title="Yes" mdxType="Tick" />
      </TableCellResult>
    </TableRow>
  </TableBody>
    </TableContainer>
    <hr></hr>
    <h2 {...{
      "id": "palettes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#palettes",
        "aria-label": "palettes permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Palettes`}</h2>
    <p>{`The Luna colour system consists of individual brand palettes and shared global palettes.`}</p>
    <h3>{`Brand palettes`}</h3>
    <p>{`Each brand has a primary palette, while brands can also have a secondary and promotional palette to further accent and distinguish the brand. As Habitat makes full use of the Luna colour system, you can see examples of our brand palettes with Habitat colours below.`}</p>
    <h4>{`Primary palette`}</h4>
    <p>{`The primary palette features the core colours of the brand. It’s important to lead with the primary palette, especially when you’re introducing the brand for the first time.`}</p>
    <GridList columns="2" mdxType="GridList">
  <ColourBlock fill="#FFFFFF" mdxType="ColourBlock">Primary 1</ColourBlock>
  <ColourBlock fill="#000000" mdxType="ColourBlock">Primary 2</ColourBlock>
    </GridList>
    <h4>{`Secondary palette`}</h4>
    <p>{`The secondary palette contains a variety of colours to accent and identify the brand. You can use the secondary palette once the user has been fully introduced to the personality of the brand. When used with the primary palette, these colours help every interaction feel on-brand and informative.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#78B48C" mdxType="ColourBlock">Leaf Base</ColourBlock>
  <ColourBlock fill="#D08178" mdxType="ColourBlock">Kiss Base</ColourBlock>
  <ColourBlock fill="#87BAB8" mdxType="ColourBlock">Water Base</ColourBlock>
  <ColourBlock fill="#D1B265" mdxType="ColourBlock">Earth Base</ColourBlock>
  <ColourBlock fill="#8889AB" mdxType="ColourBlock">Lavender Base</ColourBlock>
  <ColourBlock fill="#5D96Bf" mdxType="ColourBlock">Sky Base</ColourBlock>
  <ColourBlock fill="#CED0D1" mdxType="ColourBlock">Cloud Base</ColourBlock>
    </GridList>
    <h4>{`Promotional palette`}</h4>
    <p>{`The promotional palette consists of colours that grab the user’s attention. You can use the promotional palette when you’re promoting a sale or advertising something new.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#D50000" mdxType="ColourBlock">Sale Base</ColourBlock>
  <ColourBlock fill="#1976D2" mdxType="ColourBlock">New Base</ColourBlock>
    </GridList>
    <h3>{`Global palettes`}</h3>
    <p>{`The monochrome and semantic palettes are a group of colours shared across all brands.`}</p>
    <h4>{`Monochrome palette`}</h4>
    <p>{`The monochrome palette is a shared group of colours that is best used to provide contrast and neutrality to your designs. Use the monochrome palette for text contrast, as well as icons, backgrounds, keylines and interactive states.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#000000" mdxType="ColourBlock">Monochrome Black</ColourBlock>
  <ColourBlock fill="#262626" mdxType="ColourBlock">Monochrome Darker</ColourBlock>
  <ColourBlock fill="#404040" mdxType="ColourBlock">Monochrome Dark</ColourBlock>
  <ColourBlock fill="#737373" mdxType="ColourBlock">Monochrome Medium</ColourBlock>
  <ColourBlock fill="#D8D8D8" mdxType="ColourBlock">Monochrome Light</ColourBlock>
  <ColourBlock fill="#F2F2F2" mdxType="ColourBlock">Monochrome Lighter</ColourBlock>
  <ColourBlock fill="#FFFFFF" mdxType="ColourBlock">Monochrome White</ColourBlock>
    </GridList>
    <h4>{`Semantic palette`}</h4>
    <p>{`The semantic palette is a shared group of colours that help communicate key messages like errors, warnings, successes and understanding next steps. The consistent use of semantic colours keeps cognitive load low and makes for a unified and engaging user experience across our brands.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#D50000" mdxType="ColourBlock">Error Base</ColourBlock>
  <ColourBlock fill="#1976D2" mdxType="ColourBlock">Information Base</ColourBlock>
  <ColourBlock fill="#2E7D32" mdxType="ColourBlock">Success Base</ColourBlock>
  <ColourBlock fill="#FFD600" mdxType="ColourBlock">Warning Base</ColourBlock>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "colour-accessibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#colour-accessibility",
        "aria-label": "colour accessibility permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Colour accessibility`}</h2>
    <p>{`To comply with the Web Content Accessibility Guidelines 2.1 AA standard contrast ratios, choose colours from the global palettes that have sufficient colour contrast to make text and UI controls as easy as possible to read and distinguish.`}</p>
    <h3>{`Monochrome palette accessibility`}</h3>
    <p>{`Use Monochrome Dark (#262626) or Monochrome Black (#000000) for body text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <span style={{
          color: `${GlobalTokens.color.monochrome.dark}`
        }}>
      This body copy is using monochrome dark text.
    </span>
  </Do>
  <Dont mdxType="Dont">
    <span style={{
          color: `${GlobalTokens.color.monochrome.light}`
        }}>
      This body copy is using monochrome light text.
    </span>
  </Dont>
    </Guideline>
    <p>{`When using Monochrome White (#FFFFFF) or Monochrome Lighter (#F2F2F2) as a background colour, only use Monochrome Dark (#262626) or Monochrome Black (#000000) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill={GlobalTokens.color.monochrome.white} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.dark}`
          }}>
        Monochrome Dark Text
      </span>
    </ColourBlock>
    <ColourBlock fill={GlobalTokens.color.monochrome.lighter} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.dark}`
          }}>
        Monochrome Dark Text
      </span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill={GlobalTokens.color.monochrome.white} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.light}`
          }}>
        Monochrome Light Text
      </span>
    </ColourBlock>
    <ColourBlock fill={GlobalTokens.color.monochrome.lighter} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.medium}`
          }}>
        Monochrome Medium Text
      </span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <p>{`When using Monochrome Dark (#262626) as a background colour, only use Monochrome White (#FFFFFF), Monochrome Lighter (#F2F2F2) or Monochrome Light (#D8D8D8) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5.1</p>
    <ColourBlock fill={GlobalTokens.color.monochrome.dark} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.light}`
          }}>
        Monochrome Light Text
      </span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill={GlobalTokens.color.monochrome.dark} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.medium}`
          }}>
        Monochrome Medium Text
      </span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <h3>{`Semantic palette accessibility`}</h3>
    <p>{`When using Error Base (#D50000), Success Base (#2E7D32) or Information Base (#1976D2) as a background colour, only use Monochrome White (#FFFFFF) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill={GlobalTokens.color.semantic.error.base} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.white}`
          }} center>
        Monochrome White Text
      </span>
    </ColourBlock>
    <ColourBlock fill={GlobalTokens.color.semantic.success.base} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.white}`
          }} center>
        Monochrome White Text
      </span>
    </ColourBlock>
    <ColourBlock fill={GlobalTokens.color.semantic.info.base} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.white}`
          }}>
        Monochrome White Text
      </span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill={GlobalTokens.color.semantic.error.base} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.light}`
          }}>
        Monochrome Light Text
      </span>
    </ColourBlock>
    <ColourBlock fill={GlobalTokens.color.semantic.success.base} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.light}`
          }}>
        Monochrome Light Text
      </span>
    </ColourBlock>
    <ColourBlock fill={GlobalTokens.color.semantic.info.base} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.light}`
          }}>
        Monochrome Light Text
      </span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <p>{`When using Warning Base (#FFD600) as a background colour, only use Monochrome Dark (#262626) or Monochrome Black (#000000) text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5.1</p>
    <ColourBlock fill={GlobalTokens.color.semantic.warning.base} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.dark}`
          }}>
        Monochrome Medium Text
      </span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill={GlobalTokens.color.semantic.warning.base} center mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.medium}`
          }}>
        Monochrome Medium Text
      </span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      